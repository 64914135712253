.booking {
  display: flex;
  flex-direction: column;
  background-color: grey;
  margin: 1.1em;
  padding: 1em;
  border-radius: 8px;
  border: 2px solid white;
}

.booking-title {
  color: white;
  text-transform: uppercase;
}

.booking-date {
  font-size: 1.3em;
  color: darkred;
}

.booking-number {
  font-size: 1.3em;
  color: darkred;
}

.booking-ribbon {
  display: inline-block;
  font-size: 1.5em;
  text-transform: uppercase;
}

.animate {
  animation: animateRibbon 2s infinite linear;
}

@keyframes animateRibbon {
  0% {
    transform: scale(1);
    color: inherit;
  }

  50% {
    transform: scale(1.3);
    color:gold;
  }

  100% {
    transform: scale(1);
    color: inherit;
  }
}
