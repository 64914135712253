.homemadeloader-loader > p > span:nth-child(3n + 2) {
  display: inline-block;
  animation: animate3n 0.8s infinite linear;
}

@keyframes animate3n {
  0% {
    transform: scale(1) translateY(0px);
    color: white;
  }
  50% {
    transform: scale(1.3) translateY(36px);
    color: darkred;
  }
  100% {
    transform: scale(1) translateY(0px);
    color: white;
  }
}

.homemadeloader-loader > p > span:nth-child(3n) {
  display: inline-block;
  animation: animate2n 0.8s infinite linear;
}

@keyframes animate2n {
  0% {
    transform: scale(1) translateY(0px);
    color: white;
  }
  50% {
    transform: scale(1.3) translateY(-12px);
    color: gold;
  }
  100% {
    transform: scale(1) translateY(0px);
    color: white;
  }
}

.homemadeloader-loader > p > span:nth-child(3n + 7) {
  display: inline-block;
  animation: animate3n7 0.8s infinite linear;
}

@keyframes animate3n7 {
  0% {
    transform: scale(1) translateY(0px);
    color: white;
  }
  50% {
    transform: scale(1.3) translateY(12px);
    color: blue;
  }
  100% {
    transform: scale(1) translateY(0px);
    color: white;
  }
}

.homemadeloader-loader > p > span:nth-child(1) {
  display: inline-block;
  animation: animate1 0.8s infinite linear;
}

@keyframes animate1 {
  0% {
    transform: scale(1) translateY(0px);
    color: white;
  }
  50% {
    transform: scale(1.3) translateY(12px);
    color: blue;
  }
  100% {
    transform: scale(1) translateY(0px);
    color: white;
  }
}

.homemadeloader-loader > p > span:nth-child(4) {
  display: inline-block;
  animation: animate4 0.8s infinite linear;
}

@keyframes animate4 {
  0% {
    transform: scale(1) translateY(0px);
    color: white;
  }
  50% {
    transform: scale(1.3) translateY(12px);
    color: blue;
  }
  100% {
    transform: scale(1) translateY(0px);
    color: white;
  }
}
